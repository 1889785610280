<template>
  <div>
    <div class="container-pa32">
      <br />
      <br />
      <br />
      <div class="logo">
        <img
          class="logo-img"
          :src="require('@/assets/imgs/png/0527-a.png')"
        />
        <br />
        <img
          class="logo-word"
          :src="require('@/assets/imgs/svg/0527-b.svg')"
        />
      </div>
      <div class="form-bg">
        <form>
          <!-- TODO: 改 國碼＋電話號碼 -->
          <div class="field">
            <span class="text-875 text-b">手機號碼</span>
            <input
              id="phoneNumber"
              type="tel"
              class="input-form width-fill"
              placeholder="請輸入手機號碼"
              v-model="phoneNumber"
            />
          </div>
          <div class="field display-table">
            <span class="text-875 text-b">驗證碼</span>
            <br />
            <div class="input-with-button">
              <input
                id="verifyCode"
                type="number"
                class="input-form"
                placeholder="請輸入驗證碼"
                autocomplete="one-time-code"
                v-model="verifyCode"
                @input="$_trimCode()"
              />
              <button
                type="button"
                class="input-button"
                @click="$_getVerifyCode()"
              >
                獲取驗證碼
              </button>
            </div>
          </div>
          <div class="check-word">
            <input
              type="checkbox"
              v-model="agree"
            >
            <p>
              同意
              <span
                to="terms"
                @click="termShow =!termShow"
              >
                《用戶使用協議》
              </span>
              並登入
            </p>
          </div>
          <center>
            <button
              type="button"
              class="send input-button"
              @click="$_login()"
            >
              登入
            </button>
          </center>
        </form>
      </div>
      <br />
      <br />
      <div class="bottom-name">
        <img :src="require('@/assets/imgs/svg/0527-c.svg')" />
      </div>
    </div>
    <div class="background-decoration-top"></div>
    <div
      class="term-lightbox"
      :class="{show:termShow}"
    >
      <button @click="termShow = !termShow">X</button>
      <Terms></Terms>
    </div>
  </div>
</template>

<script>
import Terms from "@/views/Terms";
import { handleErr } from "@/helpers";
export default {
    components: {
        Terms,
    },
    data: () => ({
        phoneNumber: "",
        email: "",
        verifyCode: "",
        agree: false,
        termShow: false,
        isLoading: false,
    }),
    methods: {
        $_trimCode() {
            if (this.verifyCode.length > 6) {
                this.verifyCode = this.verifyCode.slice(0, 6);
            }
        },
        async $_getVerifyCode() {
            if (this.isLoading) return;
            if (!this.phoneNumber) {
                handleErr({ app: this.$parent, msg: "請輸入電話號碼" });
                return;
            }
            const nationCode = 886;
            const nationMobile = this.phoneNumber;
            this.isLoading = true;
            try {
                const res = await this.$axios.get(
                    `/customer/mobile/code/${nationCode}/${nationMobile}`
                    // {
                    //     code: "string",
                    //     nationCode: "string",
                    //     username: "string",
                    // }
                );
                const status = res.data.statusCode;
                if (status === 500) {
                    throw { msg: "系統繁忙，請稍後再試" };
                } else if (status === 200) {
                    throw { msg: "已送出驗證碼" };
                } else {
                    throw { msg: res.data.msg };
                }
            } catch (error) {
                handleErr({ ...error, app: this.$parent });
            }
            this.isLoading = false;
        },
        async $_bindWithLine(userToken, userMobile) {
            try {
                const lineAccessToken = await this.$liff.getAccessToken();
                const res = await this.$axios({
                    url: "/line/bind",
                    method: "post",
                    // baseURL: "https://login.pluginn.com.tw/api",
                    baseURL: "https://login.pluginn-web.com/api",
                    data: {
                        user_token: userToken,
                        user_mobile: userMobile,
                        line_access_token: lineAccessToken,
                    },
                });
                if (res.data.success) {
                    await this.$axios.post("customer/upUserInfo", {
                        nickName: res.data.line_user_name,
                    });
                } else {
                    console.log("get line display name failed");
                }
            } catch (error) {
                handleErr({ ...error, app: this.$parent });
            }
        },
        async $_login() {
            if (this.isLoading) return;
            if (!this.phoneNumber) {
                handleErr({ app: this.$parent, msg: "請輸入電話號碼" });
                return;
            }
            if (!this.verifyCode) {
                handleErr({ app: this.$parent, msg: "請輸入驗證碼" });
                return;
            }
            if (!this.agree) {
                handleErr({
                    app: this.$parent,
                    msg: "請同意用戶使用協議再繼續",
                });
                return;
            }
            const nationCode = 886;
            const nationMobile = this.phoneNumber;
            this.$axios.defaults.headers.common["appType"] = "PHONE";
            this.isLoading = true;
            try {
                const res = await this.$axios.post(`customer/loginByCode`, {
                    code: this.verifyCode,
                    nationCode: nationCode,
                    username: nationMobile,
                });
                if (res.data.success) {
                  console.log(res, 'reslogin');
                    const userToken = res.data.result.token;
                    const userMobile = res.data.result.mobile;
                    const email = res.data.result.email;
                    localStorage.setItem("token", userToken);
                    localStorage.setItem("user_mobile", userMobile);
                    this.$axios.defaults.headers.common["token"] = userToken;
                    await this.$_bindWithLine(userToken, userMobile);
                    if (!email) {
                      this.$router.push("emailCheck");
                    } else {
                      this.$router.push("map");
                    }
                    // console.log("axios", this.$axios);
                    // alert(`liff getOS: ${this.$liff.getOS()}`);
                } else {
                    throw res;
                }
            } catch (error) {
                handleErr({ ...error, app: this.$parent });
            }
            this.isLoading = false;
        }
    },
};
</script>
